

<div>
<app-header></app-header>

<div class="body" style="background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.774), rgba(255, 255, 255, 0.774)), url(assets/images/palomagris.png);">
  <router-outlet></router-outlet>
</div>


<app-footer></app-footer>
</div>

<!--
<app-dashboard></app-dashboard>
-->

