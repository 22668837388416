<div class="">
  <hr>
    <h2 id="base">
      <span><b>EN EL CONGRESO</b></span>
    </h2>
  </div>
  <div class="container centerc mt-5 mb-5">
    <div class="col-lg-11">
  
  
      <div class="row text-center">
        <div class="col-lg-3">
          <div class="centerc">
            <figure id="circle"></figure>
          </div>
          <h3><b>PLENARIA CAM REP
            </b></h3>
        </div>
  
        <div class="col-lg-3">
          <div class="centerc">
            <figure id="circle"></figure>
          </div>
          <h3><b>COMISIÓN PRIMERA
            </b></h3>
        </div>
  
        <div class="col-lg-3">
          <div class="centerc">
            <figure id="circle"></figure>
          </div>
          <h3><b>COMISIÓN DE PAZ
            </b></h3>
        </div>
  
        <div class="col-lg-3">
          <div class="centerc">
            <figure id="circle"></figure>
          </div>
          <h3><b>CONTANCIAS PONENCIAS</b></h3>
        </div>
        
      </div>
    </div>
  </div>