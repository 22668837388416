import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Article } from 'src/app/models/article';
import { Location } from '@angular/common';
import { SafePipeModule } from 'safe-pipe';

import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.css'],
})
export class ArticleComponent {
  websiteUrl: string = window.location.href;
  article: Article = {
    titulo: '',
    fecha: '',
    descripcion: '',
    imagen: '',
    documento: '',
    contenido: '',
    id: '',
    url: '',
  };
  public doc: string = '';
  viewPdf: boolean = false;
  viewurl: boolean = false;
  metaT: Meta | undefined;

  @Output() shareDoc = new EventEmitter<Article>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    private meta: Meta,
    private title: Title
  ) {
    let articulo: Article = JSON.parse(
      localStorage.getItem('article')!
    ) as Article;
    this.article = articulo;
    this.viewDocument();
    this.viewUrl();
    if (this.viewPdf) {
      this.doc = articulo.documento!;
    } else {
      this.doc = '';
    }

    window.addEventListener('popstate', () => {
      window.location.reload();
    });
  }

  ngOnInit(): void {
    let id = this.route.snapshot.paramMap.get('id');

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Aquí se ha cargado la página
        this.meta.updateTag({
          property: 'og:title',
          content: 'Nuevo título de mi página',
        });
      }
    });
  }

  viewDocument() {
    if (this.article.documento != '' && this.article.documento != null) {
      this.viewPdf = true;
    } else {
      this.viewPdf = false;
    }
  }

  viewUrl() {
    if (this.article.url != '' && this.article.url != null) {
      this.viewurl = true;
    } else {
      this.viewurl = false;
    }
  }

  onNavigate() {
    this.router.navigateByUrl(this.article.url!);
    window.location.href = this.article.url!;
  }

  goBack() {
    this._location.back();
  }

  share() {
    this.shareDoc.emit(this.article);
  }

  compartirEnFacebook() {
    const url = encodeURIComponent(window.location.href);
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      '_blank'
    );
  }

  compartirEnWhatsApp() {
    const url = encodeURIComponent(window.location.href);
    window.open(`https://wa.me/?text=${url}`, '_blank');
  }

  compartirEnTwitter() {
    const url = encodeURIComponent(window.location.href);
    const texto = encodeURIComponent(
      '¡Mira esta página! trata de ' + this.article.titulo
    );
    const via = encodeURIComponent('miusuario');
    window.open(
      `https://twitter.com/intent/tweet?url=${url}&text=${texto}&via=${via}`,
      '_blank'
    );
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    window.removeEventListener('popstate', () => {
      window.location.reload();
    });
  }
}
