<div class="">
    <hr>
    <h2 id="base">

        <span><b>Noticias relevantes</b></span>
    </h2>
</div>
<div class="container">
    <div class="editors-news">
        <div class="row">
            <!--
        <div class="col-lg-12">
          <div class="position-relative image-hover">
            <img
              src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.hdnicewallpapers.com%2FWalls%2FBig%2FFrog%2FBig_Green_Frog.jpg&f=1&nofb=1&ipt=a79f934532d082be6b938a16bf4c515ee18c996754f51797bb383e4cb0100ad0&ipo=images"
              width=" 100%;"
              height=" 400px;"
              alt="noticias-Pedro-Jose-Suarez-Vacca"
            />
          </div>
        </div>-->

            <div class="col-lg-12 mb-5 mb-sm-2 mt-3">
                <div class="row">
                    
                    <div class="col-lg-4 mt-5" *ngFor="let newsItem of principalNews;">
                        <div class="col" style="padding-left: 0px; padding-right: 0px; text-align: center">
                            <div class="box-1">
                                <div class="position-relative image-hover">
                                    <img *ngIf="newsItem" [src]="newsItem.imagen" (click)="viewArticle(newsItem)"
                                        class="img-fluid img" alt="noticias-Pedro-Jose-Suarez-Vacca" />
                                </div>
                            </div>
    
                            <h6 class="font-weight-600 mt-3">{{ newsItem.titulo }}</h6>
                            <p class="fs-15 font-weight-normal line-clamp">{{ newsItem.descripcion }}</p>
                        </div>
                    </div>
                    
                </div>

            </div>

            <!--div class="col-lg-6 mb-5 mb-sm-2 mt-3">
          <div class="row">
            <div class="col-lg-6">
              <div class="box-1" style="text-align: center">
                <div class="">
                  <div class="position-relative image-hover">
                    <img
                      [src]="news[1].imagen"
                      (click)="viewArticle(news[1])"
                      class="img-fluid"
                      alt="noticias-Pedro-Jose-Suarez-Vacca"
                    />
                  </div>
                  <h6 class="font-weight-600 p-3">
                    {{ news[1].titulo }}
                  </h6>
                </div>
  
                <div class="">
                  <div class="position-relative image-hover">
                    <img
                      [src]="news[2].imagen"
                      class="img-fluid"
                      (click)="viewArticle(news[2])"
                      alt="noticias-Pedro-Jose-Suarez-Vacca"
                    />
                  </div>
                  <h6 class="font-weight-600 p-3">
                    {{ news[2].titulo }}
                  </h6>
                </div>
              </div>
            </div>
  
            <div class="col-lg-6">
              <div class="box-1" style="text-align: center">
                <div class="">
                  <div class="" style="text-align: center">
                    <i class="text-center">
                      <a
                        class="twitter-timeline"
                        href="https://twitter.com/suarezvacca?ref_src=twsrc%5Etfw"
                        data-width="250"
                        data-height="300"
                        >Tweets by suarezvacca</a
                      >
                    </i>
                  </div>
                </div>
  
                <div class="">
                  <div style="text-align: center">
                    <div
                      class="fb-page"
                      data-href="https://www.facebook.com/suarezvacca"
                      data-tabs="timeline"
                      data-width=""
                      data-height=""
                      data-small-header="true"
                      data-adapt-container-width="true"
                      data-hide-cover="false"
                      data-show-facepile="true"
                      data-width="250"
                      data-height="300"
                    >
                      <blockquote
                        cite="https://www.facebook.com/suarezvacca"
                        class="fb-xfbml-parse-ignore"
                      >
                        <a href="https://www.facebook.com/suarezvacca"
                          >Pedro José Suárez Vacca</a
                        >
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div-->
        </div>
    </div>
</div>