<app-side-bar
  (collapsedEvent)="receiveCollapsed($event)"
  class="sidebar"
></app-side-bar>

<!-- wrapper starts -->
<div class="wrapper" (click)="toggleSidebar()">
  <div class="content shadow-sm">
    <div class="container-fluid header-container">
      <div class="row header">
        <app-header class="container-fluid" id="header-container"></app-header>
      </div>
    </div>

    <!-- Main container starts -->
    <div class="container main-container" id="main-container" v>
      <router-outlet></router-outlet>
    </div>
    <!-- Main container ends -->
  </div>

  <app-footer class="footer position-relative d-block"></app-footer>
</div>
<!-- wrapper ends -->
