import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Article } from 'src/app/models/article';
import { ProyectosLeyServiceService } from 'src/app/services/proyectos-ley-service.service';

@Component({
  selector: 'app-proyectos-ley',
  templateUrl: './proyectos-ley.component.html',
  styleUrls: ['./proyectos-ley.component.css']
})
export class ProyectosLeyComponent {
  news: Article[] = [];
  view: boolean = false;
  article: Article = {
    titulo: '',
    fecha: '',
    descripcion: '',
    imagen: '',
    documento: '',
    contenido: '',
    id: '',
  };
  constructor(private proyectoLeyService: ProyectosLeyServiceService, private router: Router) {
    window.addEventListener('popstate', () => {
      window.location.reload();
    });
  }

  ngOnInit() {
    this.getAllNews();
  }

  getAllNews() {
    this.proyectoLeyService.getProyectosLey().subscribe(data => {
      this.news = data;
    }
      );
  }

  showInfoNews(news: Article) {
    this.view = !this.view;
    this.article = news;
  }

  closeInfoNews() {
    this.view = !this.view;
  }

  viewArticle(news: Article) {
    localStorage.setItem("article", JSON.stringify(news));
    this.router.navigate(["/article",news.id] );
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    window.removeEventListener('popstate', () => {
      window.location.reload();
    });
  }
}
