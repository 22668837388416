import { Component, HostListener, OnInit } from '@angular/core';
import { Article } from 'src/app/models/article';
import { NewsServiceService } from 'src/app/services/news-service.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-news-page',
  templateUrl: './news-page.component.html',
  styleUrls: ['./news-page.component.css'],
})
export class NewsPageComponent {
  news: Article[] = [];
  view: boolean = false;
  article: Article = {
    titulo: '',
    fecha: '',
    descripcion: '',
    imagen: '',
    documento: '',
    contenido: '',
    id: '',
  };

  navigationExtras: NavigationExtras = {
    state: {
        value: null,
    },
};

  constructor(
    private newsService: NewsServiceService,
    private router: Router
  ) {
    window.addEventListener('popstate', () => {
      window.location.reload();
    });
  }

  ngOnInit() {
    this.getAllNews();
  }

  getAllNews() {
    this.newsService.getNews().subscribe((data) => {
      this.news = data;
    });
  }

  showInfoNews(news: Article) {
    this.view = !this.view;
    this.article = news;
  }

  closeInfoNews() {
    this.view = !this.view;
  }

  viewArticle(news: Article) {
    localStorage.setItem("article", JSON.stringify(news));
    this.router.navigate(["/article",news.id] );
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    window.removeEventListener('popstate', () => {
      window.location.reload();
    });
  }
}
