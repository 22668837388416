import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.css']
})
export class BodyComponent {

  constructor(private router: Router,meta: Meta) {
    meta.updateTag({ name: 'description', content: 'This is the description' });
  }

  ngOnInit(): void {
  //  window.location.reload()
  }

  reloadRoute() {
    this.router.resetConfig(this.router.config);

  }
}
