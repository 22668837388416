import { NgModule } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import {AngularFireModule } from '@angular/fire/compat';


import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import { CommonModule } from '@angular/common';

//import { BootstrapComponent } from './bootstrap/bootstrap.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { BodyComponent } from './components/body/body.component';
import { MainContentComponent } from './components/body/components/main-content/main-content.component';
import { ProyectsMainComponent } from './components/body/components/proyects-main/proyects-main.component';
import { AboutComponent } from './components/body/about/about.component';
import { RouterModule } from '@angular/router';
import { NewsComponent } from './components/body/components/news/news.component';
import { NewsPageComponent } from './components/body/news-page/news-page.component';
import { ComunicadosComponent } from './components/body/comunicados/comunicados.component';
import { ProyectosLeyComponent } from './components/body/proyectos-ley/proyectos-ley.component';
import { ConstanciasComponent } from './components/body/constancias/constancias.component';
import { ProyectoLeyApoyadosComponent } from './components/body/proyecto-ley-apoyados/proyecto-ley-apoyados.component';
import { AudienciaPublicaComponent } from './components/body/audiencia-publica/audiencia-publica.component';
import { PonenciasComponent } from './components/body/ponencias/ponencias.component';
import { PazComponent } from './components/body/paz/paz.component';
import { DerechosHumanosComponent } from './components/body/derechos-humanos/derechos-humanos.component';
import { JovenesComponent } from './components/body/jovenes/jovenes.component';
import { MedioAmbienteComponent } from './components/body/medio-ambiente/medio-ambiente.component';
import { DashboardComponent } from './components/body/dashboard/dashboard.component';
import { SideBarComponent } from './components/body/dashboard/components/side-bar/side-bar.component';
import { ArticleComponent } from './components/body/article/article.component';
import  {  PdfViewerModule  }  from  'ng2-pdf-viewer';
import { provideStorage,getStorage } from '@angular/fire/storage';
import { PropocisionesComponent } from './components/body/propocisiones/propocisiones.component';
import { SafePipeModule } from 'safe-pipe';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import { NuestrasCausasComponent } from './components/body/components/nuestras-causas/nuestras-causas.component';
import { EnElCongresoComponent } from './components/body/components/en-el-congreso/en-el-congreso.component';



@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        BodyComponent,
        MainContentComponent,
        ProyectsMainComponent,
        AboutComponent,
        NewsComponent,
        NewsPageComponent,
        ComunicadosComponent,
        ProyectosLeyComponent,
        ConstanciasComponent,
        ProyectoLeyApoyadosComponent,
        AudienciaPublicaComponent,
        PonenciasComponent,
        PazComponent,
        DerechosHumanosComponent,
        JovenesComponent,
        MedioAmbienteComponent,
        DashboardComponent,
        SideBarComponent,
        ArticleComponent,
        PropocisionesComponent,
        NuestrasCausasComponent,
        EnElCongresoComponent,
    ],
    providers: [Meta,{ provide: LocationStrategy, useClass: HashLocationStrategy }],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        AngularFireModule.initializeApp(environment.firebaseConfig, 'app-suarez-vacca'),
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideFirestore(() => getFirestore()),
        PdfViewerModule,
        provideStorage(() => getStorage()),
        SafePipeModule,
    ]
})
export class AppModule {

}
