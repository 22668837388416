<!--====== BANNER PART START ======-->
<section class="banner-slider pt-90 pb-90">

  <!--
  <div *ngFor="let sliderInfo of imgSlider" [@fadeInOut]="'in'">

    <div
      class="banner-area bg_cover d-flex align-items-center"
      style="
        background-image: url({{sliderInfo.imagen}});
        background-size: cover;
      "
    >

      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="banner-content text-center">
              <br />
              <h3
                data-animation="fadeInRightBig"
                data-delay="0.7s"
                class="title"
              >
                {{sliderInfo.titulo}}
              </h3>
              <a
                data-animation="zoomIn"
                data-delay="0.8s"
                class="main-btn"
                (click)="goToArticle(sliderInfo.categoria!, sliderInfo.id!)"
                >Mas informacion</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="banner-line" data-animation="fadeInRightBig" data-delay="2s">
      </div>
    </div>
  </div>

-->
  <!--
<div *ngFor="let sliderInfo of imgSlider">
  <div
  class="banner-area bg_cover d-flex align-items-center" id="banner"
  style="background-image: url({{sliderInfo.imagen}});
  "
>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="banner-content text-center">
          <br />
          <h3 data-animation="fadeInRightBig" data-delay="0.7s" class="title">
            {{sliderInfo.titulo}}
          </h3>
          <a
            data-animation="zoomIn"
            data-delay="0.8s"
            class="main-btn"


            (click)="goToArticle(sliderInfo.categoria!, sliderInfo.id!)"
            >Mas infomacion</a
          >
        </div>
      </div>
    </div>
  </div>
  <div class="banner-line" data-animation="fadeInRightBig" data-delay="2s">
  </div>
</div>
</div>
-->


  <div class="banner-area bg_cover d-flex align-items-center" id="banner" style="background-image: url({{imgSlider[0].imagen}});
    ">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="text-center">
            <br />
            <h3 data-animation="fadeInRightBig" data-delay="0.7s" class="title font-weight-bold"
              style="font-weight: 900;">
              {{imgSlider[0].titulo}}
            </h3>
            <br>
            <a data-animation="zoomIn" data-delay="0.8s" class="main-btn"
              (click)="goToArticle(imgSlider[0].categoria!, imgSlider[0].id!)">Mas infomacion</a>
          </div>
        </div>
      </div>
    </div>
    <div class="banner-line" data-animation="fadeInRightBig" data-delay="2s">
    </div>
  </div>

  <div class="banner-area bg_cover d-flex align-items-center" style="background-image: url({{imgSlider[1].imagen}});
  ">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="text-center">
            <h3 data-animation="fadeInRightBig" data-delay="0.7s" class="title font-weight-bold"
              style="font-weight: 900;">
              {{imgSlider[1].titulo}}
            </h3>
            <br>
            <a data-animation="zoomIn" data-delay="0.8s" class="main-btn"
              (click)="goToArticle(imgSlider[1].categoria!, imgSlider[1].id!)">Mas informacion</a>
          </div>
        </div>
      </div>
    </div>
    <div class="banner-line" data-animation="fadeInRightBig" data-delay="2s">

    </div>
  </div>

</section>

<!--====== BANNER PART ENDS background-size: 100vw 80vh;======-->