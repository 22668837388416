import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { collectionData, Firestore, addDoc } from '@angular/fire/firestore';
import { collection } from 'firebase/firestore';
import { VideoURL } from '../models/video';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  constructor(private firestore: Firestore) {}

  addNews(news: VideoURL) {
    const newsRef = collection(this.firestore, 'videoSlider');
    return addDoc(newsRef, news);
  }

  getVideo(): Observable<VideoURL[]> {
    const newsRef = collection(this.firestore, 'videoSlider');
    //{idField: 'id'}
    return collectionData(newsRef) as Observable<VideoURL[]>;
  }
}
