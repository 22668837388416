import { Injectable } from '@angular/core';
import { Article } from '../models/article';
import { Observable } from 'rxjs';
import { collectionData, Firestore, addDoc } from '@angular/fire/firestore';
import { collection } from 'firebase/firestore';


@Injectable({
  providedIn: 'root'
})
export class AudienciaServiceService {

  constructor(private firestore: Firestore) {}

  addAudiencia(news: Article) {
    const newsRef = collection(this.firestore, 'audienciasPublicas');
    return addDoc(newsRef, news);
  }

  getAudiencia(): Observable<Article[]> {
    const newsRef = collection(this.firestore, 'audienciasPublicas');
    //{idField: 'id'}
    return collectionData(newsRef) as Observable<Article[]>;
  }
}
