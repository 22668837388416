import { Component, HostListener } from '@angular/core';
import { Article } from 'src/app/models/article';
import { AudienciaServiceService } from 'src/app/services/audiencia-service.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-audiencia-publica',
  templateUrl: './audiencia-publica.component.html',
  styleUrls: ['./audiencia-publica.component.css'],
})
export class AudienciaPublicaComponent {
  news: Article[] = [];
  view: boolean = false;
  article: Article = {
    titulo: '',
    fecha: '',
    descripcion: '',
    imagen: '',
    documento: '',
    contenido: '',
    id: '',
  };
  constructor(
    private audienciaService: AudienciaServiceService,
    private router: Router
  ) {
    window.addEventListener('popstate', () => {
      window.location.reload();
    });
  }

  ngOnInit() {
    this.getAllNews();
  }

  getAllNews() {
    this.audienciaService.getAudiencia().subscribe((data) => {
      this.news = data;
    });
  }

  showInfoNews(news: Article) {
    this.view = !this.view;
    this.article = news;
  }

  closeInfoNews() {
    this.view = !this.view;
  }

  viewArticle(news: Article) {
    localStorage.setItem('article', JSON.stringify(news));
    this.router.navigate(['/article', news.id]);
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    window.removeEventListener('popstate', () => {
      window.location.reload();
    });
  }
}
