import { Component } from '@angular/core';

@Component({
  selector: 'app-nuestras-causas',
  templateUrl: './nuestras-causas.component.html',
  styleUrls: ['./nuestras-causas.component.css']
})
export class NuestrasCausasComponent {

}
