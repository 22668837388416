import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {

  collapedSideBar: boolean | undefined;

    constructor() {}

    ngOnInit() {
        const dom: any = document.querySelector('body');
        if (window.innerWidth <= 992) {
            dom.classList.remove('sidemenu-open');
        } else {
            dom.classList.add('sidemenu-open');
        }
        const marginTops = (document.querySelector('.footer') as HTMLElement).offsetHeight;
        (document.querySelector('.content') as HTMLElement).style.paddingBottom = marginTops + 10 + 'px';
        (document.querySelector('.content') as HTMLElement).style.marginBottom = '-' + marginTops + 'px';
        (document.querySelector('.loader') as HTMLElement).style.display = 'none';
    }

    toggleSidebar() {
        const dom: any = document.querySelector('body');
        if (window.innerWidth <= 992 ) {
            if (dom.classList.contains('sidemenu-open')) {
                dom.classList.remove('sidemenu-open');
            }
        }
    }

    receiveCollapsed($event: boolean) {
        this.collapedSideBar = $event;
    }
}
