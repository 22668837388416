<!-- Logo sidebar -->
<a routerLink="/dashboard" class="logo">
  <img src="assets/images/logo-principal.png" alt="" class="logo-icon mt-1">

</a>
<!-- Logo sidebar ends -->

<!-- Navigation menu sidebar-->
<h6 class="subtitle fs11">Navigation Menu</h6>
<ul class="nav flex-column">
  <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" href="javascript:void(0)" (click)="addExpandClass('controls')"><i class="material-icons icon">storage</i><span>Components</span> <i class="material-icons arrow">expand_more</i></a>
          <div class="nav flex-column" [class.d-block]="showMenu === 'controls'">

          <div class="nav-item" [routerLink]="['/productos']" [routerLinkActive]="['active']"><a class="nav-link"><span>Productos</span></a></div>

          <div class="nav-item"  [routerLink]="['/marcas']"  [routerLinkActive]="['active']"><a class="nav-link" ><span>Marcas</span></a></div>
          <div class="nav-item"  [routerLink]="['/modelos']"  [routerLinkActive]="['active']"><a class="nav-link" ><span>Modelos</span></a></div>
          <div class="nav-item"  [routerLink]="['/versiones']"  [routerLinkActive]="['active']"><a class="nav-link" ><span>Versiones</span></a></div>
          <div class="nav-item"  [routerLink]="['/category']"  [routerLinkActive]="['active']"><a class="nav-link" ><span>Categorias</span></a></div>
          <div class="nav-item"  [routerLink]="['/vehiculos']"  [routerLinkActive]="['active']"><a class="nav-link" ><span>Vehiculos</span></a></div>
      </div>
  </li>
</ul>

<!-- Navigation menu sidebar ends -->
<br>
<!-- Upgrade notification -->

<!-- Upgrade notification ends -->

<!-- Setting link -->
<ul class="nav flex-column">
  <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" href="javascript:void(0)" (click)="addExpandClass('settings')"><i class="material-icons icon">settings_applications</i><span>Gestionar usuarios</span> <i class="material-icons arrow">expand_more</i></a>
      <div class="nav flex-column" [class.d-block]="showMenu === 'settings'">
          <div class="nav-item" [routerLink]="['/usuario']" [routerLinkActive]="['active']"><a class="nav-link"><span>Usuarios</span></a></div>
          <div class="nav-item" [routerLink]="['/clientes']" [routerLinkActive]="['active']"><a class="nav-link"><span>Clientes</span></a></div>
          <div class="nav-item" [routerLink]="['/soatTecno']" [routerLinkActive]="['active']"><a class="nav-link"><span>Soat y Tecnomecánica</span></a></div>
      </div>
  </li>
</ul>
<!-- Setting link -->

<img src="assets/images/logo-principal.png" alt="" class="logo-icon mt-1">
