import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Article } from 'src/app/models/article';
import { NewsServiceService } from 'src/app/services/news-service.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent {

  news: Article[] = [];
  principalNews: Article[] = [];
  obj: Article = {
    titulo:'',
    fecha: '',
    descripcion: '',
    imagen: '',
    documento: '',
  };
  constructor(private newsService: NewsServiceService,
    private router: Router) { }

  ngOnInit() {
    this.getAllNews();
  }

  getAllNews() {
    this.newsService.getNews().subscribe((data) => {
      this.news = data;
      for (let index = 0; index < this.news.length && index < 3; index++) {
        this.principalNews.push(this.news[index]);
      }
    });
  }

  viewArticle(article: Article) {
    localStorage.setItem('article', JSON.stringify(article));
    this.router.navigate(['/article', article.id]);
  }
}
