import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Article } from '../models/article';
import { Observable } from 'rxjs';
import { collectionData, Firestore, addDoc } from '@angular/fire/firestore';
import { collection } from 'firebase/firestore';
import { ImagesSlideBar } from '../models/slide-bar-images';

@Injectable({
  providedIn: 'root'
})
export class SliderImagesService {

  constructor(private firestore: Firestore) {}

  addNews(news: ImagesSlideBar) {
    const newsRef = collection(this.firestore, 'imgSlider');
    return addDoc(newsRef, news);
  }

  getImgSlider(): Observable<ImagesSlideBar[]> {
    const newsRef = collection(this.firestore, 'imgSlider');
    //{idField: 'id'}
    console.log("ima", newsRef)
    return collectionData(newsRef) as Observable<ImagesSlideBar[]>;
  }
}
