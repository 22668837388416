import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BodyComponent } from './components/body/body.component';
import { AboutComponent } from './components/body/about/about.component';
import { NewsPageComponent } from './components/body/news-page/news-page.component';
import { ComunicadosComponent } from './components/body/comunicados/comunicados.component';
import { ProyectosLeyComponent } from './components/body/proyectos-ley/proyectos-ley.component';
import { ConstanciasComponent } from './components/body/constancias/constancias.component';
import { ProyectoLeyApoyadosComponent } from './components/body/proyecto-ley-apoyados/proyecto-ley-apoyados.component';
import { AudienciaPublicaComponent } from './components/body/audiencia-publica/audiencia-publica.component';
import { PonenciasComponent } from './components/body/ponencias/ponencias.component';
import { PazComponent } from './components/body/paz/paz.component';
import { DerechosHumanosComponent } from './components/body/derechos-humanos/derechos-humanos.component';
import { JovenesComponent } from './components/body/jovenes/jovenes.component';
import { MedioAmbienteComponent } from './components/body/medio-ambiente/medio-ambiente.component';
import { DashboardComponent } from './components/body/dashboard/dashboard.component';
import { ArticleComponent } from './components/body/article/article.component';
import { PropocisionesComponent } from './components/body/propocisiones/propocisiones.component';

const routes: Routes = [
  { path: '', component: BodyComponent },
  { path: 'acerca-de', component: AboutComponent },
  { path: 'noticias', component: NewsPageComponent },
  { path: 'comunicados', component: ComunicadosComponent },
  { path: 'proyectosLey', component: ProyectosLeyComponent },
  { path: 'constancias', component: ConstanciasComponent },
  { path: 'proyectoLeyApoyados', component: ProyectoLeyApoyadosComponent },
  { path: 'audienciaPublica', component: AudienciaPublicaComponent },
  { path: 'ponencias', component: PonenciasComponent },
  { path: 'paz', component: PazComponent },
  { path: 'derechosHumanos', component: DerechosHumanosComponent },
  { path: 'jovenes', component: JovenesComponent },
  { path: 'medioAmbiente', component: MedioAmbienteComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'article/:id', component: ArticleComponent },
  { path: 'propocisiones', component: PropocisionesComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
