<div class="container">
  <section class="page-title-area bg_cover pt-40 pb-40">
    <div>
      <div>
        <div class="container">
          <h2 class="background double"><span>Comunicados</span></h2>
        </div>
        <div class="container">
          <div>
            <div *ngFor="let newInfo of news">
              <div class="container">
                <div class="row">
                  <div class="services-item">
                    <div class="row justify-content-md-center">
                      <div class="col-lg-5 position-relative image-hover">
                        <img
                          [src]="newInfo.imagen"
                          class="img-fluid text-center"
                          alt="..."
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="">
                          <h3>{{ newInfo.titulo }}</h3>
                        </div>
                        <p>
                          {{ newInfo.descripcion }}
                        </p>
                        <button
                          type="button"
                          (click)="viewArticle(newInfo)"
                          class="btn btn-primary btn-sm"
                        >
                          Más informacion
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--
    <div>
      <div *ngIf="view">
        <app-article [article]="article"></app-article>
      </div>
    </div>-->
  </section>
</div>
