import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Article } from 'src/app/models/article';
import { ConstanciaServiceService } from 'src/app/services/constancia-service.service';

@Component({
  selector: 'app-constancias',
  templateUrl: './constancias.component.html',
  styleUrls: ['./constancias.component.css'],
})
export class ConstanciasComponent {
  news: Article[] = [];
  view: boolean = false;
  article: Article = {
    titulo: '',
    fecha: '',
    descripcion: '',
    imagen: '',
    documento: '',
    contenido: '',
    id: '',
  };

  constructor(private constanciasService: ConstanciaServiceService, private router: Router) {
    window.addEventListener('popstate', () => {
      window.location.reload();
    });
  }

  ngOnInit() {
    this.getAllNews();
  }

  getAllNews() {
    this.constanciasService.getConstancia().subscribe((data) => {
      this.news = data;
    });
  }


  showInfoNews(news: Article) {
    this.view = !this.view;
    this.article = news;
  }

  closeInfoNews() {
    this.view = !this.view;
  }

  viewArticle(news: Article) {
    localStorage.setItem("article", JSON.stringify(news));
    this.router.navigate(["/article",news.id] );
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    window.removeEventListener('popstate', () => {
      window.location.reload();
    });
  }
}
