// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'suarez-vacca',
    appId: '1:226221238235:web:81777940c56ce7ca6d69fe',
    databaseURL: 'https://suarez-vacca-default-rtdb.firebaseio.com',
    storageBucket: 'suarez-vacca.appspot.com',
    apiKey: 'AIzaSyDK8bvB6iXjgTnaMYHntL7IXDDJZjahT6U',
    authDomain: 'suarez-vacca.firebaseapp.com',
    messagingSenderId: '226221238235',
    measurementId: 'G-Z27J385WC8',
  },
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyDK8bvB6iXjgTnaMYHntL7IXDDJZjahT6U",
    authDomain: "suarez-vacca.firebaseapp.com",
    databaseURL: "https://suarez-vacca-default-rtdb.firebaseio.com",
    projectId: "suarez-vacca",
    storageBucket: "suarez-vacca.appspot.com",
    messagingSenderId: "226221238235",
    appId: "1:226221238235:web:81777940c56ce7ca6d69fe",
    measurementId: "G-Z27J385WC8"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
