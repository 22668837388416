import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Article } from 'src/app/models/article';
import { ComunicadoServiceService } from 'src/app/services/comunicado-service.service';

@Component({
  selector: 'app-comunicados',
  templateUrl: './comunicados.component.html',
  styleUrls: ['./comunicados.component.css'],
})
export class ComunicadosComponent {
  view: boolean = false;
  article: Article = {
    titulo: '',
    fecha: '',
    descripcion: '',
    imagen: '',
    documento: '',
    contenido: '',
    id: '',
  };
  news: Article[] = [];
  constructor(private comunicadoService: ComunicadoServiceService, private router: Router) {
    window.addEventListener('popstate', () => {
      window.location.reload();
    });
  }

  ngOnInit() {
    this.getAllComunicados();
  }

  getAllComunicados() {
    this.comunicadoService.getComunicado().subscribe((data) => {
      this.news = data;
    });
  }



  showInfoNews(news: Article) {
    this.view = !this.view;
    this.article = news;
  }

  closeInfoNews() {
    this.view = !this.view;
  }

  viewArticle(news: Article) {
    localStorage.setItem("article", JSON.stringify(news));
    this.router.navigate(["/article",news.id] );
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    window.removeEventListener('popstate', () => {
      window.location.reload();
    });
  }
}
