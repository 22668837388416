import { Component } from '@angular/core';
import { Article } from 'src/app/models/article';
import { VideoURL } from 'src/app/models/video';
import { ComunicadoServiceService } from 'src/app/services/comunicado-service.service';
import { VideoService } from 'src/app/services/video.service';
import { SafePipeModule } from 'safe-pipe';
import { Router } from '@angular/router';

@Component({
  selector: 'app-proyects-main',
  templateUrl: './proyects-main.component.html',
  styleUrls: ['./proyects-main.component.css'],
})
export class ProyectsMainComponent {
  news: Article[] = [];
  video: VideoURL[] = [];
  titulos: string[] = ["en medios", "columnas de opinión"]
  constructor(
    private comunicadoService: ComunicadoServiceService,
    private videoService: VideoService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getAllNews();
    this.getVideo();
  }

  getAllNews() {
    this.comunicadoService.getComunicado().subscribe((data) => {
      this.news = data;
      this.news.push(data[0]);
    });
  }

  getVideo() {
    this.videoService.getVideo().subscribe((data) => {
      this.video = data;
    });
  }

  viewArticle(article: Article) {
    localStorage.setItem('article', JSON.stringify(article));
    this.router.navigate(['/article', article.id]);
  }
}
