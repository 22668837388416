<div class="container">
  <div class="row">
    <div class="col-lg-12 mb-5 mb-sm-2 mt-3">
      <div class="row">
        <div class="col-lg-4 mt-5">
            <h2 id="base">
                <b>En redes</b>
            </h2>
            <br>
          <div class="box-1" style="text-align: center">
            <div class="" style="text-align: center">
              <a class="twitter-timeline " href="https://twitter.com/suarezvacca?ref_src=twsrc%5Etfw" data-width="auto"
                data-height="360">Tweets by suarezvacca</a>

            </div>



            <!--div style="text-align: center " style="margin: 5%;">
              <a class="fb-page" data-href="https://www.facebook.com/suarezvacca" data-tabs="timeline"
                 data-show-facepile="false" data-height="250" data-width="220">
                <blockquote cite="https://www.facebook.com/suarezvacca" class="fb-xfbml-parse-ignore">
                  <a href="https://www.facebook.com/suarezvacca">Pedro José Suárez Vacca</a>
                </blockquote>
              </a>
            </div-->

          </div>

        </div>
        <div class=" col-lg-4 mt-5 vl" *ngFor="let newsItem of news; index as i">
          <h2 id="base">
            <b>{{titulos[i]}}</b>
        </h2>
        <br>
          <div class="col" style="padding-left: 0px; padding-right: 0px; text-align: center">
            <div class="box-1">
              <div class="position-relative image-hover">
                <img *ngIf="newsItem" [src]="newsItem.imagen" (click)="viewArticle(newsItem)" class="img-fluid img"
                  alt="noticias-Pedro-Jose-Suarez-Vacca" />
              </div>
            </div>

            <h6 class="font-weight-600 mt-3">{{ newsItem.titulo }}</h6>
            <p class="fs-15 font-weight-normal line-clamp">{{ newsItem.descripcion }} </p>


          </div>
        </div>

      </div>

    </div>
    <!--div class="col-lg-6 mb-5 mb-sm-2 mt-3">
      <div class="col" style="padding-left: 0px; padding-right: 0px; text-align: center;">
        <div class="embed-container">
          <iframe [src]="video[0].url | safe : 'resourceUrl'" title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen style=" text-align: center;"></iframe>
        </div>
      </div>
    </div-->


    <!-- div class="col-lg-6 mb-5 mb-sm-2 mt-3" style="text-align: center;">
      <div class="row justify-content-md-center">

        <div class="col-lg-8 " style="text-align: center;">
          <div class="box-1" style="text-align: center;">
            <div class="position-relative image-hover">
              <img *ngIf="news.length > 0" [src]="news[0].imagen" (click)="viewArticle(news[0])" alt="world-news" />
            </div>
          </div>

          <h5 class="font-weight-600 mt-3">{{ news[0].titulo }}</h5>
          <p>{{ news[0].descripcion}}</p>
        </div>
      </div>

    </div-->
  </div>
</div>