<section class="page-title-area bg_cover pt-40 pb-40">
  <div class="container">
    <div class="editors-news">
      <div class="row">
        <div class="col-lg-12 text-center">
          <div class="container">
            <img [src]="article.imagen" />
          </div>

          <div class="container pt-30 pb-30">
            <div class="row justify-content-md-left">
              <div class="col-lg-10">
                <div class="services-item card">
                  <div class="card-body">
                    <div class="">
                      <div class="row text-left">
                        <div class="col-10">
                          <div class="offcanvas-social">
                            <ul class="">
                              <li>
                                <a (click)="compartirEnFacebook()"
                                  ><i class="fa fa-facebook-square"></i
                                ></a>
                              </li>
                              <li>
                                <a (click)="compartirEnWhatsApp()">
                                  <i class="fa fa-whatsapp"></i
                                ></a>
                              </li>

                              <li>
                                <a (click)="compartirEnTwitter()"
                                  ><i class="fa fa-twitter"></i
                                ></a>
                              </li>
                              <!--li>
                            <a href="$"><i class="fa fa-instagram"></i></a>
                          </!--li-->
                            </ul>
                          </div>
                        </div>

                        <div class="col-2">
                          <div class="container">
                            <button
                              type="button"
                              class="btn btn-primary"
                              (click)="goBack()"
                            >
                            <i class="fa fa-arrow-left"></i>
                              volver
                            </button>
                          </div>
                        </div>
                      </div>

                      <h2 class="card-title text-center">
                        <span>{{ article.titulo }}</span>
                      </h2>

                      <h6 class="card-subtitle mb-2 text-muted">
                        {{ article.fecha }}
                      </h6>
                      <p class="card-text text-left">
                        {{ article.descripcion }}
                      </p>

                      <p class="card-text text-left">{{ article.contenido }}</p>

                      <div *ngIf="viewPdf">
                        <div class="container text-left">
                          <iframe
                            [src]="doc | safe : 'resourceUrl'"
                            width="100%"
                          ></iframe>
                        </div>
                      </div>

                      <div *ngIf="viewurl">
                        <div class="text-center">
                          <button
                            type="button"
                            class="btn btn-primary"
                            (click)="onNavigate()"
                          >
                            Leer Articulo
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="col-2">
                    <div class="container">
                      <button
                        type="button"
                        class="btn btn-primary"
                        (click)="goBack()"
                      >
                      <i class="fa fa-arrow-left"></i>
                        volver
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
