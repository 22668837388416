<footer class="footer-section ">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-lg-3">
        <div class="fs-about">
          <div class="fa-social text-center">
            <a href="https://www.facebook.com/suarezvacca"
              ><i class="fa fa-facebook"></i
            ></a>
            <a href="https://twitter.com/suarezvacca"
              ><i class="fa fa-twitter"></i
            ></a>
            <a href="https://www.instagram.com/suarezvacca/"
              ><i class="fa fa-instagram"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
    <div class="" style="text-align: center;" id="logos">
      <img class="footer1" src="assets/images/logo pacto.png" alt="" />

      <img class="footer1" src="assets/images/logo c humana.png" alt="" />

      <img class="footer1" src="assets/images/logo camara de rep.png" alt="" />
    </div>
    <br>
  </div>
    <div class=" col-lg-12 background">
      <div class="row">
        <div class="col-lg-2"></div>
        <div class="col-lg-6">
          <div class="m-5 " ></div>
          <p class="footer-color">Cra 7° No. 8-68 OF 330B - cel: (+57) 320 379 4708</p>
          <p class="footer-color">Tel:(+57) 601 3904050 Ext: 3289 - 3291</p>
          <p class="footer-color">pedro.suarez@camara.gov.co / suarezvacca.camara@gmail.com</p>
          <p class="footer-color">Bogotá D.C - Colombia</p>
          
        </div>
        <div class="col-lg-3">
          
            <div class="container m-5">
              <div class="align-items-center justify-content-md-center">
                <img src="assets/images/logo-principal.png" width="70%" alt="" />
              </div>
            </div>    
            
        </div>
        
      </div>
      <div class="copyright-text">
        <div class="align-items-center justify-content-md-center">
          <p class="footer-color ">
            Copyright &copy;
            <script>
              document.write(new Date().getFullYear());
            </script>
            Todos los derechos reservados | Esta pagina esta hecha con
            <i class="fa fa-heart" aria-hidden="true"></i> por
            <a href="https://www.facebook.com/boyacaweb" target="_blank"
              >BoyacáWeb</a
            >
          </p>
        </div>
      </div>
      
      
    </div>
  
</footer>
