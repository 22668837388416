import { Injectable } from '@angular/core';
import { Article } from '../models/article';
import { Observable } from 'rxjs';
import { collectionData, Firestore, addDoc } from '@angular/fire/firestore';
import { collection } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class PonenciaServiceService {

  constructor(private firestore: Firestore) {}

  addPonencia(news: Article) {
    const newsRef = collection(this.firestore, 'ponencias');
    return addDoc(newsRef, news);
  }

  getPonencia(): Observable<Article[]> {
    const newsRef = collection(this.firestore, 'ponencias');
    //{idField: 'id'}
    return collectionData(newsRef) as Observable<Article[]>;
  }
}
