import { Injectable } from '@angular/core';
import { Article } from '../models/article';
import { Observable } from 'rxjs';
import { collectionData, Firestore, addDoc } from '@angular/fire/firestore';
import { collection } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class ConstanciaServiceService {

  constructor(private firestore: Firestore) {}

  addConstancia(news: Article) {
    const newsRef = collection(this.firestore, 'constancias');
    return addDoc(newsRef, news);
  }

  getConstancia(): Observable<Article[]> {
    const newsRef = collection(this.firestore, 'constancias');
    //{idField: 'id'}
    return collectionData(newsRef) as Observable<Article[]>;
  }


}
