<div class="">
  <hr>
  <h2 id="base">
    <span><b>NUESTRAS CAUSAS</b></span>
  </h2>
</div>
<div class="container centerc mt-5 mb-5">
  <div class="col-lg-11">


    <div class="row text-center">
      <div class="col-lg-3">
        <div class="centerc">
          <figure id="circle"></figure>
        </div>
        <h3><b>DERECHOS HUMANOS</b></h3>
      </div>

      <div class="col-lg-3">
        <div class="centerc">
          <figure id="circle"></figure>
        </div>
        <h3><b>CAMPESINADO Y AGRO</b></h3>
      </div>

      <div class="col-lg-3">
        <div class="centerc">
          <figure id="circle"></figure>
        </div>
        <h3><b>AMBIENTAL Y MINERAL</b></h3>
      </div>

      <div class="col-lg-3">
        <div class="centerc">
          <figure id="circle"></figure>
        </div>
        <h3><b>PAZ Y JUSTICIA</b></h3>
      </div>
      
    </div>
  </div>
</div>