import { Component } from '@angular/core';

@Component({
  selector: 'app-en-el-congreso',
  templateUrl: './en-el-congreso.component.html',
  styleUrls: ['./en-el-congreso.component.css']
})
export class EnElCongresoComponent {

}
