<!--====== ABOUT INTRODUCTION PART START ======

<section class="about-introduction-area pt-90 pb-90">
  <div class="row">
    <div class="col-lg-6 mb-5 mb-sm-2 mt-3">
      <div class="container text-center">
        <img src="assets/images/perfil2.jpg" width="90%" alt="" class="foto" />
      </div>
    </div>

    <div class="col-lg-6 mb-5 mb-sm-2 mt-3" id="titulo">
      <div class="container text-center">
        <div class="">
          <h2 class="">Pedro José</h2>

          <h1>Suárez Vacca</h1>
        </div>

        <div class="">
          <div class="">
            <div class="">
              <div class="" >
                <img src="assets/images/bb.png" alt="" class="" width="6%" />
                <div class="" style="margin-left: 4px; margin-top: 4px">
                  <p>FECHA DE NACIMIENTO:<br />03 OCTUBRE 1970</p>
                </div>
              </div>
            </div>

            <div class="">
              <div class="" >
                <img
                src="assets/images/lugar.png"
                alt=""
                class=""
                width="6%"
              />
              <div class="" style="margin-left: 4px; margin-top: 4px">
                <p>SOGAMOSO-BOYACÁ</p>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<br>
  <div class="col-12 text-justify" >
    <div class="">
      <div class="">
        <p class="">
          <strong>Abogado</strong> de la Universidad Católica de Colombia,
          <strong>Magister</strong> en Derecho Penal de la Universidad Libre de
          Colombia, con <strong>especialización</strong> en Derecho
          Constitucional e instituciones Jurídico Penales de la Universidad
          Nacional de Colombia.
          <br />
          Fue <strong>elegido</strong> como Representante a la Cámara por el
          departamento de Boyacá para el período 2022 – 2026 por el Pacto
          Histórico con <strong>22.353 votos.</strong><br />
          Nacido en la ciudad de <strong>Sogamoso</strong>, departamento de
          Boyacá, el 3 de octubre de 1970. Ha vivido la mayor parte de su vida
          en Tunja, en donde se hizo bachiller académico del Colegio de Boyacá.
          <br />
          Fue <strong>aspirante a la alcaldía de Tunja</strong> en la coalición
          programática y política entre los partidos Colombia Humana-UP,
          Movimiento Alternativo Indígena y Social (MAIS) y el Polo Democrático
          Alternativo, logrando <strong>12.056 votos</strong>, una cifra récord
          hasta el momento para los movimientos alternativos de la ciudad.
          <br />
          Cuenta con una <strong>experiencia profesional</strong> de más de
          Veinticinco <strong>(25)</strong> años. Laboró inicialmente en el
          sector público, habiéndose desempeñado en diversos cargos como
          funcionario público entre ellos, en la Fiscalía General de la
          República, sede Tunja, en la Alcaldía del municipio de Motavita, en
          Alcaldía del municipio de Garagoa, en la Defensoría del Pueblo como
          Defensor Público en el departamento de Boyacá y como Juez de la
          República.
          <br />
          Igualmente, se desempeñó durante más de 12 años como
          <strong>profesor</strong> en la facultad de derecho de la Universidad
          de Boyacá, de la UPTC de Tunja y la Santo Tomás de la misma ciudad.
          <br />
          Se ha destacado como abogado
          <strong>defensor de Derechos Humanos</strong> de los movimientos
          sociales, del sector campesino, del movimiento transportador y
          estudiantil.
          <br />
        </p>
      </div>
    </div>
  </div>


</section>
-->

<body>
  <section class="about-introduction-area pt-30 pb-30">
    <div class="container ">
      <div class="col-10 m-auto">
        <!--
        <div class="col-lg-6 m-0">
          <div class="">
            <div class="contenedor">
              <img src="assets/images/foto.png" />
              <div class="centrado">
                <div class="">
                  <h2 class="">Pedro José</h2>

                  <h1>Suárez Vacca</h1>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="">
          <div class="contenedor">
            <div class="image-layer"></div>
            <h1>PEDRO JOSÉ<br>SUÁREZ VACCA</h1>

          </div>
        </div>
        <div class="col-lg-6 m-0">
          <p>
            Se ha destacado como abogado
            <strong>defensor de Derechos Humanos</strong> de los movimientos
            sociales, del sector campesino, del movimiento transportador y
            estudiantil. Recientemente en el año 2021, actúo como abogado de la
            primera línea jurídica, en defensa de los jóvenes movilizados en
            Boyacá, durante el paro nacional.
          </p>
        </div>

        <div class="contenedor">
          <img src="assets/images/foto.png" alt="Imagen" />
          <p>Texto de la imagen</p>
          <p class="parrafo-derecha">Párrafo a la derecha</p>
        </div>-->
        <!--
          <img src="assets/images/foto.png" alt="Imagen" />
          <div class="texto-imagen">
            <h1>PEDRO JOSÉ<br />SUÁREZ VACCA</h1>
          </div>
          <p class="parrafo-derecha">
            Se ha destacado como abogado
            <strong>defensor de Derechos Humanos</strong> de los movimientos
            sociales, del sector campesino, del movimiento transportador y
            estudiantil. Recientemente en el año 2021, actúo como abogado de la
            primera línea jurídica, en defensa de los jóvenes movilizados en
            Boyacá, durante el paro nacional.
          </p>
        -->
        <!---->
        <span class=" pt-5 mt-5">
          <h1>PEDRO JOSÉ<br /><strong>SUÁREZ VACCA</strong></h1>
        </span>
        <div class="contenedor">

          <p style="
              background-image: linear-gradient(
                  to bottom,
                  rgba(255, 255, 255, 0.774),
                  rgba(255, 255, 255, 0.774)
                ),
                url(assets/images/palomagris.png);
              background-size: 70% 70%;
              background-repeat: no-repeat;
              background-position: right;
            ">
            <img src="assets/images/foto.png" align="left" />
            ¡Bienvenidos! Permítanme presentarme, soy Pedro José Suárez Vacca,
            Representante a la Cámara por Boyacá, abogado egresado de la Universidad
            Católica de Colombia y Magíster en Derecho Penal de la Universidad Libre. Realicé
            una especialización en Derecho Constitucional e Instituciones Jurídico Penales en la
            Universidad Nacional de Colombia.
            Nací el 3 de octubre de 1970 en Sogamoso, Boyacá, pero he vivido la mayor parte
            de mi vida en Tunja, donde me gradué como bachiller académico del Colegio de
            Boyacá. Actualmente represento a mi departamento en la Cámara de
            Representantes, habiendo obtenido el respaldo de 22,353 votantes con el Pacto
            Histórico.
            Desde mi juventud, he estado involucrado en diversos movimientos y partidos de la
            izquierda colombiana. Inicié mi militancia en la UNO (Unión Nacional de Oposición),
            guiado por mis padres y el Dr. Óscar Dueñas Ruiz, quien más tarde se convirtió en
            presidente de la Unión Patriótica. A finales de la década de 1980, participé
            activamente en las campañas del entonces M-19 y apoyé el proceso de
            consolidación de la UP (Unión Patriótica), de la cual fui miembro hasta su
            exterminio.
            Mi compromiso con los ideales de la izquierda ha marcado mi trayectoria desde
            temprana edad. Posteriormente, contribuí a la construcción y desarrollo del
            movimiento 'Ciudadanos por Boyacá', trabajando de la mano del sacerdote Gustavo
            Suárez Niño.
            Con las reformas políticas subsiguientes, comencé a respaldar el proyecto
            progresista del entonces senador Gustavo Petro Urrego y me integré a la Colombia
            Humana. En esta coalición, participé activamente en los principales eventos
            electorales.
            Con más de 25 años de experiencia profesional, he ocupado diversos cargos en el
            sector público, incluyendo la Fiscalía General de la República, fui asesor externo en
            las alcaldías de Motavita y Garagoa, y la Defensoría del Pueblo como Defensor
            Público en Boyacá, además de me desempeñé como Juez de la República.
            Mi labor como juez me enfrentó a la persecución del Gobierno del entonces
            presidente Álvaro Uribe Vélez y su ministro de Justicia y del Interior, Fernando
            Londoño Hoyos, quienes buscaban desacreditar la independencia y autonomía de la
            Rama Judicial del Poder Público. Este episodio resultó en la destitución del citado
            ministro, quien fue inhabilitado para ocupar cargos públicos por 12 años, frustrando
            así la aspiración presidencial de uno de los más radicales líderes de la derecha
            colombiana.
            Durante más de 12 años, impartí clases en la facultad de derecho de la Universidad
            de Boyacá, UPTC de Tunja y la Santo Tomás de la misma ciudad. Como abogado
            defensor de Derechos Humanos, he representado a movimientos sociales, el sector
            campesino, y el movimiento transportador y estudiantil.
            En 2021, me desempeñé como abogado de la primera línea jurídica, defendiendo a
            los jóvenes movilizados en Boyacá durante el paro nacional, demostrando mi
            compromiso con la justicia y los derechos civiles.
            Previo a mi posición actual, fui aspirante a la alcaldía de Tunja en una coalición
            entre los partidos Colombia Humana-UP, Movimiento Alternativo Indígena y Social
            (MAIS), y el Polo Democrático Alternativo, logrando una cifra récord de 12,056 votos
            para movimientos alternativos en mi ciudad.
            ¡Gracias por conocerme! Estoy aquí para servirle y representarlo con honestidad y
            dedicación, de manera que juntos hagamos de Boyacá y Colombia una Potencial
            Mundial de la Vida.
            <br />


            <span class="texto-dos">
              <!--
              <img src="assets/images/bb.png" alt="" class="" width="6%" /> FECHA DE NACIMIENTO:<br />03 OCTUBRE 1970-->
              <!--div class="row inline-block">
                <div class="col-lg-4 m-0 text-center">
                  <img src="assets/images/bb.png" alt="" class="" width="50%" />
                </div>

                <div class="col-lg-8 m-0 text-left" style="margin-left: 4px; margin-top: 4px">
                  <p>FECHA DE NACIMIENTO:<br />03 OCTUBRE 1970</p>
                </div>
              </div>

              <div class="row inline-block">
                <div class="col-lg-4 m-0 text-center">
                  <img src="assets/images/bb.png" alt="" class="" width="50%" />
                </div>

                <div class="col-lg-8 m-0 text-left" style="margin-left: 4px; margin-top: 4px">
                  <p>FECHA DE NACIMIENTO:<br />03 OCTUBRE 1970</p>
                </div>
              </div-->
              <!--
              <div class="">
                <img
                  src="assets/images/lugar.png"
                  alt=""
                  class=""
                  width="6%"
                />
                <div class="" style="margin-left: 4px; margin-top: 4px">
                  <p>SOGAMOSO-BOYACÁ</p>
                </div>
              </div>-->
            </span>

            <!--
            <br />

            -->
          </p>
          <!--
          <div class="">
            <div class="">
              <div class="">
                <div class="">
                  <img src="assets/images/bb.png" alt="" class="" width="6%" />
                  <div class="" style="margin-left: 4px; margin-top: 4px">
                    <p>FECHA DE NACIMIENTO:<br />03 OCTUBRE 1970</p>
                  </div>
                </div>
              </div>

              <div class="">
                <div class="">
                  <img
                    src="assets/images/lugar.png"
                    alt=""
                    class=""
                    width="6%"
                  />
                  <div class="" style="margin-left: 4px; margin-top: 4px">
                    <p>SOGAMOSO-BOYACÁ</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          -->
        </div>
        <br>
        <div class="items">
          <div class="container">
            <div class="item-head">
              <p>
                <span>
                  <img src="assets/images/representate.png" alt="" class="" width="10%" />
                  <strong> Representante a la cámara</strong> por el departamento de
                  Boyacá</span>
              </p>
            </div>
            <li>
              <ul>
                <strong>Periodo</strong>
                2022 - 2026
              </ul>
              <ul>
                <strong>Pacto Histórico</strong>
                con 22.353 votos
              </ul>
            </li>
          </div>

          <div class="container">
            <div class="item-head">
              <p>
                <span>
                  <img src="assets/images/candidatos.png" alt="" class="" width="10%" /><strong> Aspirante a la
                    alcaldía</strong> de Tunja</span>
              </p>
            </div>
            <li>
              <ul>
                <strong>Coalición programática y política</strong>
                entre los partidos Colombia Humana-UP, Movimiento Alternativo
                Indígena y Social (MAIS) y el Polo Democrático Alternativo
              </ul>
              <ul>
                <strong>12.056 votos</strong>, una cifra récord hasta el momento para los movimientos
                alternativos de la ciudad.
              </ul>
            </li>
          </div>

          <div class="container">
            <div class="item-head">
              <p>
                <span>
                  <img src="assets/images/bachiller.png" alt="" class="" width="10%" /><strong> Bachiller
                    académico</strong> del Colegio de
                  Boyacá</span>
              </p>
            </div>
            <li>
              <ul>
                Ha
                <strong>vivido</strong>
                la mayor parte de su vida en Tunja
              </ul>
              <ul>
                Pacto Histórico con 22.353 votos
              </ul>
            </li>
          </div>

          <div class="container">
            <div class="item-head">
              <p>
                <span><img src="assets/images/experiencia.png" alt="" class="" width="10%" />
                  <strong> Experiencia profesional</strong> de mas de 25 años</span>
              </p>
            </div>
            <li>
              <ul>
                <strong>Sector público</strong>, en diversos cargos como funcionario publico entre ellos, en la
                Fiscalía General de la República, sede Tunja, Alcaldía del municipio
                de Motavita, Alcaldía del municipio de Garagoa
              </ul>
              <ul>
                <strong>Defensoría del pueblo</strong>
                como Defensor Público en el departamento de Boyacá y como Juez de la
                República
              </ul>
              <ul>
                <strong>Profesor</strong>, se desempeñó durante más de 12 años en la facultad de derecho de
                la Universidad de Boyacá, de la UPTC de Tunja y la Santo Tomás de la
                misma ciudad.
              </ul>
            </li>
          </div>
        </div>
      </div>

    </div>


  </section>
</body>