import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Article } from '../models/article';
import { Observable } from 'rxjs';
import { collectionData, Firestore, addDoc } from '@angular/fire/firestore';
import { collection } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class NewsServiceService {



  constructor(private firestore: Firestore) {}

  addNews(news: Article) {
    const newsRef = collection(this.firestore, 'noticias');
    return addDoc(newsRef, news);
  }

  getNews(): Observable<Article[]> {
    const newsRef = collection(this.firestore, 'noticias');
    //{idField: 'id'}
    return collectionData(newsRef) as Observable<Article[]>;
  }

}
