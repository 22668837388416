<!--====== OFFCANVAS MENU PART START ======-->

<div class="off_canvars_overlay"></div>
<div class="offcanvas_menu">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="offcanvas_menu_wrapper">
          <div class="canvas_close">
            <a href="javascript:void(0)"><i class="fa fa-times"></i></a>
          </div>
          <div class="offcanvas-social">
            <ul class="text-center">
              <li>
                <a href="https://www.facebook.com/suarezvacca"><i class="fa fa-facebook-square"></i></a>
              </li>
              <li>
                <a href="https://twitter.com/suarezvacca"><i class="fa fa-twitter"></i></a>
              </li>
              <li>
                <a href="https://www.instagram.com/suarezvacca/"><i class="fa fa-instagram"></i></a>
              </li>

            </ul>
          </div>
          <div id="menu" class="text-left">
            <ul class="offcanvas_main_menu">
              <li class="menu-item-has-children active">
                <a class="active" href="#home" routerLink="/">INICIO</a>
              </li>
              <li class="menu-item-has-children active">
                <a>PERFIL</a>
                <ul class="sub-menu">
                  <li>
                    <a href="#acerca-de" routerLink="acerca-de">Conoce a Pedro José Suarez Vacca</a>
                  </li>
                  <!--<li><a href="index-2.html">Transparencia</a></li>-->
                </ul>
              </li>
              <li class="menu-item-has-children active">
                <a>PRENSA</a>
                <ul class="sub-menu">
                  <li>
                    <a href="#comunicados" routerLink="comunicados">Comunicados de prensa</a>
                  </li>
                  <li><a href="#noticias" routerLink="noticias">Noticias</a></li>
                </ul>
              </li>
              <li class="menu-item-has-children active">
                <a href="#">TRABAJAMOS POR</a>
                <ul class="sub-menu">
                  <li>
                    <a href="#derechosHumanos" routerLink="derechosHumanos">La defensa de los Derechos Humanos</a>
                  </li>
                  <li>
                    <a href="#medioAmbiente" routerLink="medioAmbiente">Proteger el medio ambiente y la minería</a>
                  </li>
                  <li>
                    <a href="#noticias" routerLink="noticias">Dignificar el campesinado y la soberanía alimentaria</a>
                  </li>
                  <li>
                    <a href="#noticias" routerLink="noticias">La construcción de la paz y la justicia social</a>
                  </li>
                </ul>
              </li>
              <li class="menu-item-has-children active">
                <a href="#">EN EL CONGRESO</a>
                <ul class="sub-menu">
                  <li>
                    <a href="#comunicados" routerLink="comunicados">Plenaria Cámara de Representantes</a>
                  </li>
                  <li>
                    <a href="#noticias" routerLink="noticias">Comisión Primera de la Cámara de Representantes</a>
                  </li>
                  <li>
                    <a href="#paz" routerLink="paz">Comisión de Paz</a>
                  </li>
                </ul>
              </li>
              <li class="menu-item-has-children active">
                <a href="#">CAUSAS CIUDADANAS</a>
                <ul class="sub-menu">
                  <li><a href="#paz" routerLink="paz">Paz</a></li>
                  <li>
                    <a href="#derechosHumanos" routerLink="derechosHumanos">Derechos humanos</a>
                  </li>
                  <li>
                    <a href="#jovenes" routerLink="jovenes">jóvenes</a>
                  </li>
                  <li>
                    <a href="#medioAmbiente" routerLink="medioAmbiente">medio ambiente</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="offcanvas_footer">
            <span><a href="mailto:pedro.suarez@camara.gov.co"><i class="fa fa-envelope-o"></i>
                pedro.suarez@camara.gov.co</a></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--====== OFFCANVAS MENU PART ENDS ======-->
<header class="header-area">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="header-top d-flex justify-content-between align-items-center">
          <div class="header-info">
            <ul>
              <!--li><a href="#"><i class="flaticon-email"></i> pedro.suarez@camara.gov.co</a></!--li-->
              <li>
                <a href="#"><i class="flaticon-placeholder"></i> Edificio Nuevo del
                  Congreso – Cra.7 No 8-68 Oficina 330B, Bogota - Colombia</a>
              </li>
            </ul>
          </div>
          <div class="social">
            <ul>
              <li>
                <a href="https://www.facebook.com/suarezvacca"><i class="fa fa-facebook-official"></i></a>
              </li>
              <li>
                <a href="https://twitter.com/suarezvacca"><i class="fa fa-twitter"></i></a>
              </li>
              <li>
                <a href="https://www.instagram.com/suarezvacca/"><i class="fa fa-instagram"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-header">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="main-header-item">
            <div class="main-header-menus d-flex justify-content-between align-items-center">
              <div class="col-lg-2">
                <div class="header-logo">
                  <a href="#"><img src="assets/images/logo-principal.png" class="principal" alt="logo" /></a>
                </div>
              </div>

              <div class="col-lg-10">
                <div class="header-menu d-none d-lg-block">
                  <ul>
                    <li>
                      <a class="active" href="#">INICIO</a>
                    </li>
                    <li class="menu-item-has-children active">
                      <a>PERFIL</a>
                      <ul class="sub-menu">
                        <li>
                          <a href="#acerca-de" routerLink="acerca-de">Conoce a Pedro José Suarez Vacca</a>
                        </li>
                        <!--<li><a href="#">Transparencia</a></li>-->
                      </ul>
                    </li>

                    <li class="menu-item-has-children active">
                      <a href="#">PRENSA</a>
                      <ul class="sub-menu">
                        <li>
                          <a href="#comunicados" routerLink="comunicados">Comunicados de prensa</a>
                        </li>
                        <li><a href="#noticias" routerLink="noticias">Noticias</a></li>
                      </ul>
                    </li>

                    <!-- menu de Trabajamos de la mano por -->
                    <li class="menu-item-has-children active">
                      <a href="#">TRABAJAMOS POR</a>
                      <ul class="sub-menu">
                        <li>
                          <a href="#derechosHumanos" routerLink="derechosHumanos">La defensa de los Derechos Humanos</a>
                        </li>
                        <li>
                          <a href="#medioAmbiente" routerLink="medioAmbiente">Proteger el medio ambiente y la
                            minería</a>
                        </li>
                        <li>
                          <a href="#noticias" routerLink="noticias">Dignificar el campesinado y la soberanía
                            alimentaria</a>
                        </li>
                        <li>
                          <a href="#noticias" routerLink="noticias">La construcción de la paz y la justicia social</a>
                        </li>
                      </ul>
                    </li>

                    <!-- menu de EN EL CONGRESO -->
                    <li class="menu-item-has-children active">
                      <a href="#">EN EL CONGRESO</a>
                      <ul class="sub-menu">
                        <li>
                          <a href="#comunicados" routerLink="comunicados">Plenaria Cámara de Representantes</a>
                        </li>
                        <li>
                          <a href="#noticias" routerLink="noticias">Comisión Primera de la Cámara de Representantes</a>
                        </li>
                        <li>
                          <a href="#paz" routerLink="paz">Comisión de Paz</a>
                        </li>
                      </ul>
                    </li>
                    <!-- menu de Trabajo Legislativo
                    <li class="menu-item-has-children active">
                      <a href="#">Trabajo Legislativo</a>
                      <ul class="sub-menu">
                        <li>
                          <a href="#constancias" routerLink="constancias">Constancias</a>
                        </li>
                        <li>
                          <a href="#proyectosLey" routerLink="proyectosLey">Proyectos de ley</a>
                        </li>
                        <li>
                          <a href="#proyectoLeyApoyados" routerLink="proyectoLeyApoyados">Proyectos de ley apoyados</a>
                        </li>
                        <li>
                          <a href="#audienciaPublica" routerLink="audienciaPublica">Audiencias publicas</a>
                        </li>
                        <li>
                          <a href="#ponencias" routerLink="ponencias">Ponencias</a>
                        </li>
                        <li>
                          <a href="#propocisiones" routerLink="propocisiones">Proposiciones</a>
                        </li>
                      </ul>
                    </li>-->
                    <li class="menu-item-has-children active">
                      <a href="#">CAUSAS CIUDADANAS</a>
                      <ul class="sub-menu">
                        <li><a href="#paz" routerLink="paz">Paz</a></li>
                        <li>
                          <a href="#derechosHumanos" routerLink="derechosHumanos">Derechos humanos</a>
                        </li>
                        <li>
                          <a href="#jovenes" routerLink="jovenes">jóvenes</a>
                        </li>
                        <li>
                          <a href="#medioAmbiente" routerLink="medioAmbiente">medio ambiente</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>

              <!--
              <div class="">
                <div class="header-info d-flex align-items-center">
                  <div class="search d-none d-sm-block">

                    <a href="#">
                      <svg
                        height="22"
                        width="22"
                        fill="#838694"
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 512 512"
                        style="enable-background: new 0 0 512 512"
                        xml:space="preserve"
                      >
                        <g>
                          <g>
                            <path
                              d="M508.875,493.792L353.089,338.005c32.358-35.927,52.245-83.296,52.245-135.339C405.333,90.917,314.417,0,202.667,0
                                              S0,90.917,0,202.667s90.917,202.667,202.667,202.667c52.043,0,99.411-19.887,135.339-52.245l155.786,155.786
                                              c2.083,2.083,4.813,3.125,7.542,3.125c2.729,0,5.458-1.042,7.542-3.125C513.042,504.708,513.042,497.958,508.875,493.792z
                                               M202.667,384c-99.979,0-181.333-81.344-181.333-181.333S102.688,21.333,202.667,21.333S384,102.677,384,202.667
                                              S302.646,384,202.667,384z"
                            />
                          </g>
                        </g>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
-->
              <div class="toggle-btn ml-30 canvas_open d-lg-none d-block">
                <i class="fa fa-bars"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>