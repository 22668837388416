import { Component } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'suarez-vacca';

  constructor(private cookieService: CookieService) {}

  ngOnInit() {
    this.fixYouTubeCookies();
  }

  fixYouTubeCookies() {
    const cookieValue = this.cookieService.get('VISITOR_INFO1_LIVE');
    if (cookieValue) {
      //this.cookieService.set('VISITOR_INFO1_LIVE', cookieValue, 0, "", "", false, 'None');
      this.cookieService.set('myCookie', 'cookieValue', { expires: 7, sameSite: 'None', secure: true });
    }
  }
}


