import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ImagesSlideBar } from 'src/app/models/slide-bar-images';
import { Article } from 'src/app/models/article';
import { SliderImagesService } from 'src/app/services/slider-images.service';
import { JovenesServiceService } from 'src/app/services/jovenes-service.service';
import { AudienciaServiceService } from 'src/app/services/audiencia-service.service';
import { ComunicadoServiceService } from 'src/app/services/comunicado-service.service';
import { ConstanciaServiceService } from 'src/app/services/constancia-service.service';
import { ProyectosLeyServiceService } from 'src/app/services/proyectos-ley-service.service';
import { ProyectosLeyApoyadosServiceService } from 'src/app/services/proyectos-ley-apoyados-service.service';
import { PonenciaServiceService } from 'src/app/services/ponencia-service.service';
import { ProposicionesServiceService } from 'src/app/services/proposiciones-service.service';
import { PazServiceService } from 'src/app/services/paz-service.service';
import { DerechosHumanosServiceService } from 'src/app/services/derechos-humanos-service.service';
import { MedioAmbienteServiceService } from 'src/app/services/medio-ambiente-service.service';
import { NewsServiceService } from 'src/app/services/news-service.service';

@Component({
  selector: 'app-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.css'],
})
export class MainContentComponent/* implements AfterViewInit*/ implements OnInit {
  imgSlider: ImagesSlideBar[] = [];
  num: number = 0;
  article: Article = {
    titulo: '',
    fecha: '',
    descripcion: '',
    imagen: '',
    documento: '',
    contenido: '',
    id: '',
  };
  articles: Article[] = [];

  constructor(
    private sliderService: SliderImagesService,
    private router: Router,
    private jovenesService: JovenesServiceService,
    private audienciaService: AudienciaServiceService,
    private comunicadoService: ComunicadoServiceService,
    private constanciaService: ConstanciaServiceService,
    private proyectosLeyService: ProyectosLeyServiceService,
    private proyectosLeyApoyadosService: ProyectosLeyApoyadosServiceService,
    private ponenciaService: PonenciaServiceService,
    private proposicionesService: ProposicionesServiceService,
    private pazService: PazServiceService,
    private derechosHumanosService: DerechosHumanosServiceService,
    private medioAmbienteService: MedioAmbienteServiceService,
    private newsService: NewsServiceService
  ) {

  }

  ngOnInit() {
    this.getAllNews();
    /*
   this.router.events.forEach(event => {
      if (event instanceof NavigationEnd) {
        setTimeout(()=>{
          this.getAllNews();
        });

      }
    });*/
  }

  getAllNews() {
    this.sliderService.getImgSlider().subscribe((data) => {
      this.imgSlider = data;
      console.log(this.imgSlider);
    });
    this.num = this.imgSlider.length;
  }

  goToArticle(option: string, titulo: string) {
    switch (option) {
      case 'Noticias':
        this.newsService.getNews().subscribe((data) => {
          this.articles = data;
          for (let index = 0; index < this.articles.length; index++) {
            const element = this.articles[index];
            if (titulo === element.titulo) {
              localStorage.setItem('article', JSON.stringify(element));
              this.router.navigate(['/article', element.id]);
            }
          }
        });
        break;
      case 'Comunicados':
        this.comunicadoService.getComunicado().subscribe((data) => {
          this.articles = data;
          for (let index = 0; index < this.articles.length; index++) {
            const element = this.articles[index];
            if (titulo === element.titulo) {
              localStorage.setItem('article', JSON.stringify(element));
              this.router.navigate(['/article', element.id]);
            }
          }
        });
        break;
      case 'Constancias':
        this.constanciaService.getConstancia().subscribe((data) => {
          this.articles = data;
          for (let index = 0; index < this.articles.length; index++) {
            const element = this.articles[index];
            if (titulo === element.titulo) {
              localStorage.setItem('article', JSON.stringify(element));
              this.router.navigate(['/article', element.id]);
            }
          }
        });
        break;
      case 'Proyectos de Ley':
        this.proyectosLeyService.getProyectosLey().subscribe((data) => {
          this.articles = data;
          for (let index = 0; index < this.articles.length; index++) {
            const element = this.articles[index];
            if (titulo === element.titulo) {
              localStorage.setItem('article', JSON.stringify(element));
              this.router.navigate(['/article', element.id]);
            }
          }
        });
        break;
      case 'Proyectos de Ley Apoyados':
        this.proyectosLeyApoyadosService.getProyectoLeyA().subscribe((data) => {
          this.articles = data;
          for (let index = 0; index < this.articles.length; index++) {
            const element = this.articles[index];
            if (titulo === element.titulo) {
              localStorage.setItem('article', JSON.stringify(element));
              this.router.navigate(['/article', element.id]);
            }
          }
        });
        break;
      case 'Audiencias':
        this.audienciaService.getAudiencia().subscribe((data) => {
          this.articles = data;
          for (let index = 0; index < this.articles.length; index++) {
            const element = this.articles[index];
            if (titulo === element.titulo) {
              localStorage.setItem('article', JSON.stringify(element));
              this.router.navigate(['/article', element.id]);
            }
          }
        });
        break;
      case 'Ponencias':
        this.ponenciaService.getPonencia().subscribe((data) => {
          this.articles = data;
          for (let index = 0; index < this.articles.length; index++) {
            const element = this.articles[index];
            if (titulo === element.titulo) {
              localStorage.setItem('article', JSON.stringify(element));
              this.router.navigate(['/article', element.id]);
            }
          }
        });
        break;
      case 'Proposiciones':
        this.proposicionesService.getproposicion().subscribe((data) => {
          this.articles = data;
          for (let index = 0; index < this.articles.length; index++) {
            const element = this.articles[index];
            if (titulo === element.titulo) {
              localStorage.setItem('article', JSON.stringify(element));
              this.router.navigate(['/article', element.id]);
            }
          }
        });
        break;
      case 'Paz':
        this.pazService.getPaz().subscribe((data) => {
          this.articles = data;
          for (let index = 0; index < this.articles.length; index++) {
            const element = this.articles[index];
            if (titulo === element.titulo) {
              localStorage.setItem('article', JSON.stringify(element));
              this.router.navigate(['/article', element.id]);
            }
          }
        });
        break;
      case 'Derechos Humanos':
        this.derechosHumanosService.getDerechosHumanos().subscribe((data) => {
          this.articles = data;
          for (let index = 0; index < this.articles.length; index++) {
            const element = this.articles[index];
            if (titulo === element.titulo) {
              localStorage.setItem('article', JSON.stringify(element));
              this.router.navigate(['/article', element.id]);
            }
          }
        });
        break;
      case 'Jovenes':
        this.jovenesService.getJovenes().subscribe((data) => {
          this.articles = data;
          for (let index = 0; index < this.articles.length; index++) {
            const element = this.articles[index];
            if (titulo === element.titulo) {
              localStorage.setItem('article', JSON.stringify(element));
              this.router.navigate(['/article', element.id]);
            }
          }
        });
        break;
      case 'Medio Ambiente':
        this.medioAmbienteService.getMedioAmbiente().subscribe((data) => {
          this.articles = data;
          for (let index = 0; index < this.articles.length; index++) {
            const element = this.articles[index];
            if (titulo === element.titulo) {
              localStorage.setItem('article', JSON.stringify(element));
              this.router.navigate(['/article', element.id]);
            }
          }
        });
        break;
      default:
        break;
    }
  }

  /*ngAfterViewInit(): void {
    console.log('ngAfterViewInit Iniciado');
    this.sliderService.getImgSlider().subscribe((data) => {
      this.imgSlider = data;
    });
  }*/
}
